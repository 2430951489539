import React from 'react';
import {
    injectStripe,
    CardElement
} from 'react-stripe-elements';
import {
    Form, FormGroup,
    Container, // even if the row is <FormGroup row>, you need this
    Row,
    Col,
    Label, Input,
    Button,
    Alert
} from 'reactstrap';

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                color: '#424770'
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};
const centsToDollars = (cents) => {
    //returns a string for the number of dollars
    var dollars = (cents / 100).toFixed(2)
    if (dollars.endsWith('00')) {
        dollars = (cents / 100).toFixed()
    }
    return dollars
}
const getLicenseNames = (base_name, nb_licenses) => {
    //gives a list of license names for display
    if (base_name === '') {
        return ''
    }
    if (nb_licenses > 1) {
        var ln_str = "["
        for (var i = 0; i < nb_licenses; i++) {
            var suffix = '00' + (i + 1)
            ln_str += base_name + "-" + suffix.slice(-2) + ','
        }
        return ln_str.slice(0, -1) + "]"
    }
    return '"' + base_name + '"' //only one license
}
class _CardForm extends React.Component {
    constructor(props) {
        super(props)
        // four phases:
        //    '', the default, when user is filling out form
        //    'pending', after hitting 'charge' button, but before 'charge' page returns
        //    'success', if charge returns good,
        //    'failure', if charge returns bad
        this.unit_price = 4500
        this.state = {
            customerName: '',
            licenseName: '',
            email: '',
            nbLicenses: 1,
            cardInfoReady: false,
            totalPriceString: centsToDollars(this.unit_price),
            licenseNames: '',
            purchasePhase: '',
            successMessage: 'Thank You! License information will be in your inbox.',
            failureMessage: 'Oh No! Something has gone wrong with the purchase.'
        }
    }
    handleCustomerNameChange = (e) => {
        this.setState({ customerName: e.target.value })
    }
    handleLicenseNameChange = (e) => {
        if (e.target.value !== '') {
            this.setState({
                licenseName: e.target.value,
                licenseNames: getLicenseNames(e.target.value, this.state.nbLicenses)
            })
        } else {
            this.setState({ licenseName: e.target.value })
        }
    }
    handleCardChange = (card_info) => {
        this.setState({
            cardInfoReady: card_info.complete
        })
    }
    handleEmailChange = (e) => {
        this.setState({ email: e.target.value })
    }
    handleNbLicensesChange = (e) => {
        var nb_licenses = e.target.value
        if (nb_licenses < 1) {
            nb_licenses = 1
        }
        if (nb_licenses > 99) {
            nb_licenses = 99
        }
        this.setState({
            nbLicenses: nb_licenses,
            totalPriceString: centsToDollars(nb_licenses * this.unit_price),
            licenseNames: getLicenseNames(this.state.licenseName, nb_licenses)
        })
    }
    totalPriceInCents = () => {
        return this.unit_price * this.state.nbLicenses
    }
    commitTheCharge = (token_payload, mf_metadata) => {
        this.setState({ purchasePhase: 'pending' })
        var url = "/charge"
        if (process.env.NODE_ENV !== 'production') {
            url = "http://localhost:5000/charge"
        }
        var data = { ...token_payload, ...mf_metadata }

        return fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            referrer: "no-referrer",
            body: JSON.stringify(data)
        })
            .then(response => response.json()) // parses response to JSON
            .then(resp => this.handleChargeSuccess(resp))
            .catch(error => this.handleChargeFailure(error))
    }
    handleChargeSuccess = (resp) => {
        if (resp.purchase_status === 'success') {
            this.setState({ purchasePhase: 'success',
                            successMessage: resp.message
                            })
        }else{
            this.handleChargeFailure(resp)
        }
    }
    handleChargeFailure = (error) => {
        var failmess = "Our servers reported an unknown Failure."
        if (error.message) {
            failmess = error.message
        }
        this.setState({ purchasePhase: 'failure',
                        failureMessage: "Oh no! The purchase didn't go through. "+failmess
                       })
    }
    handleSubmit = (ev) => {
        ev.preventDefault();
        var mf_metadata = {
            'customer_name': this.state.customerName,
            'customer_email': this.state.email,
            'product': 'mfC4',
            'license_name': this.state.licenseName,
            'nb_of_licenses': this.state.nbLicenses
        }
        if (this.props.stripe) {
            this.props.stripe
                .createToken() //stripe automatically collected its info from Elements
                .then((payload) => this.commitTheCharge(payload, mf_metadata))
                .catch((resl) => console.log('failed to commit the charge'));
        } else {
            // This has to do with async loading of stripe.js, see docs
            console.log("Stripe.js hasn't loaded yet.");
        }
    };
    getPaymentLine = () => {
        switch (this.state.purchasePhase) {
            case 'pending':
                return <Row><Col md={{ size: 10, offset: 1 }}><Alert color='primary' toggle={this.onDismissPaymentAlert}>Please wait while your Credit Card is processed.</Alert></Col></Row>;
            case 'success':
                return <Row><Col md={{ size: 10, offset: 1 }}><Alert color='success' toggle={this.onDismissPaymentAlert}>{this.state.successMessage}</Alert></Col></Row>;
            case 'failure':
                return <Row><Col md={{ size: 10, offset: 1 }}><Alert color='danger' toggle={this.onDismissPaymentAlert}>{this.state.failureMessage}</Alert></Col></Row>;
            default:
                return <FormGroup row>
                    <Label for='cardelement' md={{ size: 2, offset: 1 }}>Card details</Label>
                    <Col md='3' style={{ position: 'relative', top: '-10px' }}>
                        <CardElement id='cardelement'
                            onChange={this.handleCardChange}
                            {...createOptions(this.props.fontSize)}
                        />
                    </Col>
                    <Col md='2'>
                        <Button color='primary' disabled={!this.state.cardInfoReady}>
                            Pay &#36;{this.state.totalPriceString} </Button>
                    </Col>
                </FormGroup>;
        }
    }
    onDismissPaymentAlert = () => {
        this.setState({
            // customerName: '',
            // licenseName: '',
            // email: '',
            // nbLicenses: 1,
            cardInfoReady: false,
            // totalPriceString: centsToDollars(this.unit_price),
            // licenseNames: '',
            purchasePhase:''})
    }
    render() {
        return (
            <Container>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                        <Label for="custname" md={{ size: 2, offset: 1 }}>Customer Name</Label>
                        <Col md='3'>
                            <Input id="custname" type="text" name="customer_name" placeholder="User's name…" required
                                onChange={this.handleCustomerNameChange} />
                        </Col>
                        <Col md='3' style={{ textAlign: 'left' }}> Your name for my records.
                        <div className='hidden-text'>hidden text</div>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="licensename" md={{ size: 2, offset: 1 }}>License Name</Label>
                        <Col md='3'>
                            <Input id="licensename" type="text" name="license_name" placeholder="License name…" required
                                onChange={this.handleLicenseNameChange} />
                        </Col>
                        <Col md='3' style={{ textAlign: 'left' }}>Choose a short name to identify the license <em>(e.g.,'EditRoom1')</em>.</Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="emailaddress" md={{ size: 2, offset: 1 }}>Valid Email Address</Label>
                        <Col md={{ size: 3 }}>
                            <Input id="emailaddress" type="email" name="email_address" placeholder="email address…" required
                                onChange={this.handleEmailChange} />
                        </Col>
                        <Col md='5' style={{ textAlign: 'left' }}>Your license key will be delivered to this address.
                    <br /> It will NOT be used for spam and will never be given or sold to anyone.</Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={{ size: 2, offset: 1 }}>Number of Licenses</Label>
                        <Col md='1'>
                            <Input type='number' value={this.state.nbLicenses} onChange={this.handleNbLicensesChange} />
                        </Col>
                        <Col style={{ textAlign: 'left' }}>{this.state.licenseNames}</Col>
                    </FormGroup>
                    {this.getPaymentLine()}
                </Form>
            </Container>
        );
    }
}
export const CardForm = injectStripe(_CardForm);