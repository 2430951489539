import React from 'react';
import img1 from '../img/carousel/app_coffee_crop01.jpg'
import img2 from '../img/carousel/blur_shuffle_crop01.jpg'
import img3 from '../img/carousel/mfC_dcommand_crop01.jpg'
import img4 from '../img/carousel/pt_screen_cu_crop01.jpg'
import img5 from '../img/carousel/xport_buttons_crop01.jpg'
import mfCLogoImage from '../img/brand/mf_change_logo_flat_rounded_corners.png'
import { UncontrolledCarousel, Jumbotron, Button } from 'reactstrap';

const items = [
  {
    src: img1,
    caption: ''
  },
  {
    src: img2,
    caption: ''
  },
  {
    src: img3,
    caption: ''
  },
  {
    src: img4,
    caption: ''
  },
  {
    src: img5,
    caption: ''
  }
];
const logo_overlay_style = {
  position: 'absolute',
  left: '10%',
  // left: '25px',
  top: '30px'
}
const hero_overlay_style = {
  position: 'absolute',
  left: '50%',
  // left: '450px',
  top: '90px',
  marginBottom: '30px',
  maxHeight: '250px',
  padding: '20px',
  fontSize: '18px',
  opacity: 0.75
}
class MfcCarousel extends React.Component {
  download_link = "/download?clicked=true"
  // download_link = "http://localhost:5000/download?clicked=true" ;//local dev for testing
  download_onclick_handler = () => {
    //clumsy, because it leaves an open blank window on top
    // but also causes unpredicted havoc in the backend.
    //DONT USE THIS, leaving it here as a scarecrow
    window.open(this.download_link,'','noopener')
  }
  render() {
    return <div>
      <UncontrolledCarousel
        items={items}
        controls={false}
        indicators={false}
        style={{ height: '200px' }} />
      <div style={logo_overlay_style}>
        <img src={mfCLogoImage} alt="mfchangenote change note and change list conforming application logo" height="360px" width="360px" />
      </div>
      <Jumbotron style={hero_overlay_style}>
        <h1 className='display-3' style={{ fontWeight: '500' }}>mfChangeNote</h1>
        <h5 style={{ fontWeight: '200', textAlign: 'left' }}>Change notes for sound editors...and more.</h5>
        <br />
        <Button
          href={this.download_link}
          id='downloadbutton'
          color='primary'
          rel='nofollow'
          target='_blank'>
          Download v4.4 and try it &raquo;</Button>
        <small>&nbsp;Mac OS X 10.8+ 64-bit Intel only
          <br/>&nbsp;MD5 checksum:&nbsp;f9a56f43be342e9eb5c9ec0e55dede99
        </small>
      </Jumbotron>
    </div>
  }
}

export default MfcCarousel