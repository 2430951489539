import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import {
    CardForm
} from './StoreElements'

import mfClogoicon from '../img/brand/mf_change_logo_flat_rounded_corners.png'
import stripebadge from "../img/stripe/powered_by_stripe.png"

const apiKey = process.env.REACT_APP_STRIPE_API_KEY 
// check and change in .env or .env.production

const dev_notice = () => {
    if (process.env.NODE_ENV !== 'production') {
        return <><small style={{color:'goldenrod'}}>Testing on {apiKey}</small></>
    }
    return <></>
}

class MfcBuy extends Component {
    render() {
        return <div className='App-content'>
            <div style={{ textAlign: 'center' }}>
                <br/><br/>
                <h3>Purchase your license for</h3>
                <h2><img src={mfClogoicon} alt='' style={{width:'40px',height:'40px'}}/> mfChangeNote v4... only $45! <small>sales tax and processing fees included!</small></h2>
                <p>Fill in all boxes, including Credit Card info.</p>
                <p>Your license will by instantly delivered by email.</p>
                <hr class='soften'/>
            </div>
            <StripeProvider apiKey={apiKey}>
                <Elements>
                    <CardForm />
                </Elements>
            </StripeProvider>
            {dev_notice()}
            <div>
                <a href="http://www.stripe.com" target="_blank" rel="noopener noreferrer"><img  src={stripebadge} alt="stripe logo"/></a>
            </div>
            <div>
                <small>Your Credit Card information is securely transmitted directly to Stripe.com, and is never seen, handled or stored by mfChangeNote.</small>
            </div>
            <div>
                <hr class='soften' />
                <h3>Refunds</h3>
                <p>Contact me if you feel you need a refund, and you will get one.</p>
                <hr class="soften"/>
                <hr class="soften"/>
                <p><br/></p><p><br/></p><p><br/></p>
            </div>
        </div>
    }
}
export default MfcBuy