import React from 'react';
import auth01 from '../img/doc/auth01.png'
// import example_text from '../img/doc/example_text.png'
import makenew03 from '../img/doc/v43/makenew03.png'
import create_menu from "../img/doc/v43/create_menu.png"
import create_window_full from "../img/doc/v43/create_window_full.png"
import create_track_choice from "../img/doc/v43/create_track_choice.png"
import takeout_putbackin from "../img/doc/v43/takeout_putbackin.png" 
import time_format_menu from "../img/doc/v43/time_format_menu.png"
import time_conversion from "../img/doc/v43/time_conversion.png"
import group_closed from "../img/doc/v43/group_closed.png"
import group_open from "../img/doc/v43/group_open.png"
import checking01 from"../img/doc/checking01.png" 
import name_and_print from "../img/doc/v43/name_and_print.png"
import simplify_menu from "../img/doc/v43/simplify_menu.png"
import send_menu_onPT from "../img/doc/v43/send_menu_onPT.png"
import send_menu_run from "../img/doc/v43/send_menu_run.png"
import run_sheet_during_5 from "../img/doc/v43/run_sheet_during_5.png"

export const manual_contents = () => <div className='manual_contents'>
    <h3>Contents</h3>
    <ul>
        <li>
            <a href="#introduction">
                Introduction</a>
        </li>
        <li>
            <a href="#installing">
                Installing and Authorizing</a>
        </li>
        <li>
            <a href="#open_avid">
                Importing a Changenote</a>
        </li>
        <li>
            <a href="#make_new">
                Making a New Changenote</a>
        </li>
        <li>
            <a href="#create">
                Creating a Changenote from Adobe Premiere Pro</a>
        </li>
        <li>
            <a href="#time">
                Time Formats</a>
        </li>
        <li>
            <a href="#using">
                Using a Changenote</a>
            <ul>
                <li>
                    <a href="#using">
                        Basics</a>
                </li>
                <li>
                    <a href="#printing">
                        Printing</a>
                </li>
                <li>
                    <a href="#editing">
                        Simplifying and Editing</a>
                </li>
                <li>
                    <a href="#protools">
                        Changenotes and ProTools</a>
                </li>
                <li>
                    <a href="#conforming">
                        Before Conforming</a>
                </li>
                <li>
                    <a href="#automation">
                        Automated Conforming</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#tabtext">
                Exporting Tab-delimited Text</a>
        </li>
        <li>
            <a href="#producing_text">
                Producing Acceptable Text Files</a>
        </li>
        <li>
            <a href="#xml_tips">
                Tips for best results</a>
        </li>
    </ul>
    <a href="#themfchangenotemanual">Back to top</a>
</div>

export const manual_body = () => <div className='manual_body'>
	<h1 id="themfchangenotemanual">
	    The mfChangeNote Manual
	</h1>

	<hr class="soften" />
    <h3 class="lead" id="introduction">
        Introduction</h3>
    <hr class="soften"/>
	<p>
		<strong> mfChangeNote </strong> is a software tool that streamlines 
		the common chore of conforming (also known as &#8216;re-conforming&#8217;) 
		sound tracks during post-production.
		<strong> mfChangeNote </strong> is needed whenever picture changes require 
		corresponding changes to previously cut audio tracks like temp mixes, 
		cut elements, pre-mixes or masters. Picture assistants may also use 
		<strong> mfChangeNote </strong> to make change notes for the sound department
		as part of a turnover, especially assistants using 
		<strong> Adobe Premiere Pro </strong> workflows.
	</p>
	<p>
		<strong>mfChangeNote</strong> serves several functions:
	</p>
	<ul>
		<li> computation of changenotes from edited sequences in Adobe Premiere 
		    Pro, </li>
		<li> displaying and printing changes in an easy to understand form, </li> 
		<li> editing and modifying changenotes to correct errors or simplify their use, </li> 
		<li> performing keystrokes in Pro Tools to make changes easier, including the 
		    automatic performance of long sequences of changes, </li> 
		<li> calculation of mathematical sums in feet+frames or timecode. </li> 
	</ul>
    <hr class="soften"/>
	<h3 class="lead" id="installing">
		Installing and Authorizing
	</h3>
	<hr class="soften"/>
	<p>
		Install <strong> mfChangeNote </strong>  by dragging the downloaded application 
        file directly to your Applications folder, 
		or anywhere on your computer. On some computers, it may be necessary to change 
		the security rules for applications found in the &#8220;Security &amp; 
		Privacy&#8221; preferences pane before running <strong> mfChangeNote</strong>.
	</p>
	<p>
		<strong> mfChangeNote </strong> runs on OS X 10.8 or higher. New versions may occasionally
		appear for download on the <strong> mfChangeNote </strong> website. The application
		can tell you when the current version has been updated.
	</p>
	<p>
		Upon running the application, you will see the splash screen, which allows you to enter a 
        license key for permanent, authorized use, or to run in demo mode for 30 days. The demo mode 
        is fully-functional. After 30 days from the first run, however, you will need to buy a license 
        in order to use the program. 
	</p>
	<img src={auth01} alt="auth splash link" />
	<p>
		Licenses can be purchased directly through this site's <a href="/buy">Buy page</a>. When you see the splash 
		screen after launching <strong>mfChangeNote</strong> , click the &#8220;Enter License Key&#8220; button,
		and a dialogue will appear, allowing you to enter your personal key, delivered by email immediately after 
		purchase. You may Paste it with ⌘-V or by Right-clicking in the text field. Once the key has 
		been entered, the application will be available forever, and the splash screen will no longer 
		appear at startup.
	</p>
	<p>
		Please read the EULA. You may only use your license key to authorize a computer for your own use. 
        You may not give your license key to a friend. If you install on a shared, rented, or public computer, 
        please de-authorize <strong>mfChangeNote</strong> once it is no longer being used by you. 
        The de-authorization button can be found in the Help menu.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="open_avid">
		Importing a Changenote from Avid-style change lists
	</h3>
    <hr class="soften"/>
	<p><strong>mfChangeNote</strong> accepts special text files from Avid and some other programs. 
    Many <strong>mfChangeNote</strong> users will begin by importing one of these lists. 
    To learn more about the source of these text files, see &nbsp;
		<a href="#producing_text">
			Producing Acceptable Text Files</a>.
	</p>
	<p>
		Simply open your text file with ⌘-O &#8220;Open or Import&#8230;&#8221;. Proper change notes can come 
        from the picture workstation in a variety of slightly different layouts, and there is no standard 
        appearance. <strong>mfChangeNote</strong> will do its best to adapt to the particular layout of your 
        text. If you can't get the result you want, try to format your text to match the example shown here.
	</p>
    <p>The "At This Footage" and "For This Length" fields are mandatory, 
        and <strong>mfChangeNote</strong> expects them to be formatted as 35mm Feet and Frames.
    "Do This" and "Clip Name" fields will be used if present. Other fields, like the "At This Record TC" 
    and "Total Change" in the example below, will be ignored.</p>
	<p>
		
	</p>

	{/* <figure>
		<img src={example_text} alt="example text" />
		<figcaption><small>
			The text fragment above will import correctly into mfChangeNote.
		</small></figcaption>
	</figure> */}
	<figure>
	    <pre>{`
------------------------------------------------------------                   
                                                                               
         At This     For This                                          Total   
          Footage     Length     Do This         Clip Name            Change   
                                                                               
_    1.  0372+02   -0007+13      Trim Tail       046_550            -0007+13   
         0379+14    00:00:05:04                  046_550            00:00:06:12
                                                                               
_    2.  0372+02   +0005+08      Insert  Shot    046_565            -0002+05   
         0377+09    00:00:03:15                  046_565            00:00:01:22
	    `}</pre>

		<figcaption><small>
			The text fragment above will import correctly into mfChangeNote.
		</small></figcaption>
	</figure>
	<p></p>	<p>If <strong>mfChangeNote</strong> is having trouble importing a list from your show's workstation, please contact me.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="make_new">
		Making a New Changenote
	</h3>
	<hr class="soften"/>
	<p>
		It is not necessary to import your change note from text or create it from pairs of XMLs. Instead, you may create your own from a blank page.<br/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		Typing ⌘-N will give you a new blank changenote that you can add changes to yourself. ⌘-A and ⌘-I will add new rows. Use Tab in a selected row to start typing. </p>
		<p>Of course, you will have to manually figure out what your changes are, but once you&#8217;ve done it, you can give it to your friends, print, automate, convert, and perform math just like any other changenote.
	</p>
	<figure>
		<img src={makenew03} alt="new blank sheet" />
		<figcaption><small>
			You may start with nothing and add your own data.
		</small></figcaption>
	</figure>
	
	<hr class="soften"/>
	<h3 class="lead" id="create">
		Creating a Changenote from Adobe Premiere Pro
	</h3>
    <hr class="soften"/>
	<p><strong>mfChangeNote</strong> is able to produce a changenote by comparing two sequences exported 
    as XML from Adobe Premiere Pro. 
        Premiere can output sequence data in the "xmeml" XML file schema, developed originally by Apple
		 for Final Cut Pro 7. 
	</p>
	<p>Final Cut Pro X XML files are different, and will not work here.</p>
	<p>
        The process is simple. The accuracy of the result depends on certain factors under the control 
        of the picture department. This manual has a &nbsp;
		<a href="#xml_tips">
			helpful list of tips
		</a>
		&nbsp;covering that topic.
	</p>
	<h4 id="how">How</h4>
	<p>
		Start by typing ⌘-K or selecting the &#8220;Create&#8230;&#8221; 
        File menu item. <strong>mfChangeNote</strong> will prompt you two times for the xml files it needs. 
        The first file should be the &#8220;old&#8221;, &#8220;before&#8221; or &#8220;from&#8221; version, the second one should be the &#8220;new&#8221;, &#8220;after&#8221;, or &#8220;to&#8221; version.
	</p>
	<img src={create_menu} alt="command kay view link" />
	<p>
		If successful, the Creation Window will present you with both lists in tables for you to visually inspect. Squint hard and briefly look over the baffling array of data there. In most cases, you will see a reassuring similarity between the two lists. Be worried if you see generic or missing clip names, lots of clips with the word &#8220;gap&#8221; in the name, or outrageously huge or small numbers, especially in the duration field.
	</p>
    
	<img src={create_window_full} alt="tables of clips" />
	<p>
		Any track belonging to the sequences is available for comparison. I recommend you compare the V1 picture tracks, prepared as described &nbsp;
		<a href="#xml_tips">
			below</a>. You select the tracks to be used by choosing from the pop-up buttons appearing above each list, left and right.
	</p>
	<img src={create_track_choice} alt="track popup menu" />
	<p>
		If you need to reverse the comparison, the button with the double arrows will switch the lists. If you need to pick a different file for either list, double-click the table and you may choose another. 
	</p>
	<p>
		When you are ready, click the &#8220;generate&#8221; button and your changenote will be calculated in short order.
	</p>
	<h4 id="create_shotmoves">
		Shot Moves
	</h4>
	<p>
		In the course of normal editing progress, shots will come and go and change size quite a bit, but less often they will move around within the cut. When shot moves do happen, it presents difficulties for the conforming editor, especially when automation of the change operations is desired.
	</p>
	<p>
		<strong>mfChangeNote</strong> will present shot moves in a particular pattern. At the place where a move is to begin, 
        all the necessary deletions will occur together at once, followed by the insertion of the same 
        clips in a different place in the reel. By grouping these things together, automated or manually 
        performing these changes is made simpler. The deletions will have comments starting with 
        &#8220;take out&#8221; and the insertions will have comments starting with &#8220;put back in&#8221;. 
        Picture crews should refrain from using these strings in clip and sequence names if possible, as it 
        might impede automated conforming.
	</p>
	<figure>
		<img src={takeout_putbackin} alt="shot move example" />
		<figcaption><small>
			shot moves have comments reading "take out" and "put back in"
		</small></figcaption>
	</figure>
	<h4 id="regardingsupportforcmx-formateditlists">
		Regarding Support for CMX-format Edit Lists
	</h4>
	<p>
		Many post-production applications support interchange of edit data in the form of CMX-format edit lists, 
        so naturally, it would be sensible to expect <strong>mfChangeNote</strong> to be able to compute 
        changenotes from a pair of lists in this form. 
        In fact, it would be dog-simple to have CMX support in <strong>mfChangeNote</strong> this very minute. 
	</p>
	<p>
		<strong>mfChangeNote</strong> rejects the CMX format in the hope that it will finally die its
			long-deserved death. My apologies to all of you still offlining 1&quot; C-type video.
	</p>
	<p>
		On the other hand, an extreme outpouring of love from paying customers might persuade me otherwise. And who&#8217;s up for AAF?
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="time">
		Time Formats
	</h3>
    <hr class="soften"/>
	<p>
		<strong>mfChangeNote</strong> can show its time values in one of 5 formats:
	</p>
	<ul>
		<li>
			35mm Feet and Frames, the default for most operations.
		</li>
		<li>
			Timecode in 30 frame, 25 frame or 24 frame counting bases.
		</li>
		<li>
			Total number of frames only.
		</li>
	</ul>
	<p>
		The time format of a changenote can be changed using the &#8220;Time Format&#8221; menu. An option to convert is also given.
	</p>
	<img src={time_format_menu} alt="time formats menu" />
	<h4 id="conversion">
		Conversion
	</h4>
	<p>
		Ordinarily, when changing the time format, the total number of frames at every instant is kept the same.
         For instance, a 30 frame time code of 00:01:00:24 would convert into 35mm 114+00. 
         Both values represent 1824 movie frames. This is often the desired behavior when changing formats. 
         Sometimes, however, a user may need to change the code format&#8217;s counting base in relation to 
         its timebase. In such a situation you would use the &#8220;Convert framerate&#8230;&#8221; menu item. 
         The previous example would render a 35mm feet frames value of 91+03. The conversion to 24 frames 
         per second resulted in a value slightly larger than 1459 frames of film. Due to the rounding of the
          fractional frame, &nbsp;
		<strong>
			mfChangeNote
		</strong>
		&nbsp;will ask you to save the destructively-converted changenote to new file.
	</p>

	<img src={time_conversion} alt="time format conversion" />
	<p>
		Conversions should be done if you want to see what the appropriate 30 frame timecode values 
        are for 24 frame telecine transfers. This sort of workflow is pretty rare in modern practice.
	</p>
	<h4 id="regardingtimecode">
		Regarding Timecode
	</h4>
	<p>
		There are two things you should know about timecodes in &nbsp;
		<strong>
			mfChangeNote</strong>. First, calculations between Timecode and Frames or Feet and Frames 
            will always start counting from Hour 0. Currently, &nbsp;
		<strong>mfChangeNote</strong> makes no provision for Local Zero-ing of counters. 
	</p>
	<p>
		Secondly, <strong>mfChangeNote</strong> does not support DropFrame code. 
        Refuses to, actually. Users with advanced post-production requirements should 
        not be using DropFrame code in the day-to-day workflow of critical projects.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="using">
		Using a Changenote
	</h3>
    <hr class="soften"/>
	<p>
		Once you have your changenote, you should give it a title and add the LFOA of the old version. 
        The Old LFOA number is not created automatically. <strong>mfChangeNote</strong> saves changenotes 
        as files with the extension .mfC, the native <strong>mfChangeNote</strong> filetype.
	</p>
	<h4 id="thechangenoteinterface">
		The Changenote Interface
	</h4>
	<p>
		Each note is a table whose rows are edit operations, &#8220;<strong>changes</strong>&#8221;, either deletions or insertions, ordered sequentially. Performing these edits in order upon material that is in sync with the old version will resync that material to the new version, as far as possible.
	</p>
	<h4 id="groups">
		Groups
	</h4>
	<p>
		Several changes may be happening at the exact same place, like three consecutive shots removed together, or a tail trim of one shot followed by a head extension of the next.
	</p>
	<p>
		<strong>mfChangeNote</strong> will combine connected changes into a group that can be collapsed 
        into a single summary line, or expanded to reveal every event. Expandable groups will have a small
         black triangle on the left. Click it to toggle the state of the group between expanded and collapsed.
	</p>
	<figure>
		<img src={group_closed} alt="groups closed" />
		<figcaption><small>
			The group of changes 14 to 18 is collapsed.
		</small></figcaption>
	</figure>
	<figure>
		<img src={group_open} alt="groups open" />
		<figcaption><small>
			The group of changes 14 to 18 is expanded after clicking the triangle
		</small></figcaption>
	</figure>
	<p></p>
	<p>
		⌥-[ (option-left square bracket) will collapse every group in your change note, and ⌥-] (option-right square bracket) will expand them all.
	</p>
	<p>
		When printing, these groups will appear collapsed or expanded just as they do on screen.
	</p>
	<h4 id="checkingtheboxes">
		Checking the Boxes
	</h4>
	<p>
		Each row of the changenote has a check box on the right, just before the Comments field. By checking this box, the duration (the number in the Δ column) of the checked row will be added to a global sum. The sum of all the checked rows is the &#8220;SubTotal&#8221; value, found in a text field at the bottom of the changenote. For easy addition and subtraction, you can type any footage into the &#8220;User Footage&#8221; text field and see the results in the Sum and Difference fields to the right.
	</p>
	<img src={checking01} alt="check boxes" />
	<figcaption><small>The &quot;SubTotal&quot; field is a global sum of all check-marked changes.</small></figcaption>
	<p>
		Double-clicking a checkbox will check all boxes from the top of the list to the clicked row. 
		This is helpful for figuring out what the current reel length should be when you are in the middle 
		of a long list of changes. It&#8217;s also good when you want to pick one particular sound from somewhere in 
		the old version and move it to its new location.
	</p>
	<p>
		Double-clicking the heading above the checkbox column will clear all the check boxes.
	</p>
	<h4 id="multiselect">
		Multiple Selection
	</h4>
	<p>
		For some operations, you will need to select more than one row at a time.
		&nbsp;<strong>
			mfChangeNote
		</strong>&nbsp;
		will select all rows in a range between two ⌘-clicked rows. You do this by ⌘-clicking any unselected 
		row, then ⌘-click any other. All rows between will now be selected. If you double-click any selected 
		check box now, all selected rows will check together, exclusively.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="printing">
		Printing
	</h3>
    <hr class="soften"/>
	<p>
		<strong>
			mfChangeNote
		</strong>&nbsp;
		will print your hard-copy or PDF changenotes in a clear, readable layout. The Expanded/Collapsed state of the open changenote window will be preserved in the printed output.
	</p>
	<figure>
		<img src={name_and_print} alt="print example" />
		<figcaption><small>
			Name it, add an LFOA, and expand or collapse rows before printing.
		</small></figcaption>
	</figure>
	
	<hr class="soften"/>
	<h3 class="lead" id="editing">
		Simplifying and Editing
	</h3>
    <hr class="soften"/>
	<p>
		With &nbsp;<strong>mfChangeNote</strong>, any changenote may be modified, whether it was imported from a text file, 
            or created new. It works like a spreadsheet or word processor for changenotes.
	</p>
	<p>
		The top row of a grouped set of changes is not editable, but you may change any of the other 
        rows by clicking the row and tabbing through the editable fields.
	</p>
	<p>
		Cut and Paste with Shift-⌘-X and Shift-⌘-V. Pasted changes will be inserted above the first 
        selected row, or at the end of the changenote if no row is selected. ⌘-clicking will allow you 
        to select a range for cutting. You may cut and paste between document windows if you wish.
	</p>
	<p>
		A blank row can be added to your changenote using ⌘-A (to append) or ⌘-I (to Insert above the 
        selected row).
	</p>
	<h4 id="simplifymenuitems">
		Simplify Menu Items
	</h4>
	<img src={simplify_menu} alt="simplify menu" />
	<p>
		The Simplify menu has several options that will tweak your changenote by reducing the number of 
        changes in special ways:
	</p>
	<ul>
		<li>
			<strong>
				Simplify:Selection
			</strong>&nbsp;
			combines all currently selected changes into one single row. The new change will have 
            the same &#8220;from&#8221; footage as the first selected row, and its duration will be the 
            sum of all the selected changes combined.
		</li>
		<li>
			<strong>
				Simplify:Entire Note
			</strong>&nbsp;
			will combine each group into a single change of one summary row only, wiping out any changes 
            that have zero duration.
		</li>
		<li>
			<strong>
				Simplify:Entire Note Keeping Zeroes
			</strong>&nbsp;
			will simplify the entire note, but groups that combine to have zero duration (same amount going in as coming out) will not disappear from the list.
		</li>
		<li>
			<strong>
				Simplify:Net Zero Changes Only
			</strong>&nbsp;
			removes any group or row that has no net duration.
		</li>
		<li>
			<strong>
				Simplify:Short Even Swaps Only
			</strong>&nbsp;
			removes changes with zero net duration composed of two shots, each being shorter than 16 frames.
		</li>
		<li>
			<strong>
				Simplify:Long Even Swaps Only
			</strong>&nbsp;
			removes changes with zero net duration composed of two shots, but only when those shots are 16 frames or longer.
		</li>
	</ul>
	<h4 id="ripplecutandpaste">
		Ripple Cut and Paste
	</h4>
	<p>
		The Edit menu contains three variations on Cut and Paste called &nbsp;
		<strong>
			Ripple Cut</strong>, 
		<strong>&nbsp;
			Ripple Paste
		</strong>&nbsp;
		and &nbsp;
		<strong>
			Ripple Paste Over</strong>.
	</p>
	<p>
		Each of these commands will change the &#8220;from&#8221; footage values of changes 
		&nbsp;<strong>
			greater than
		</strong>&nbsp;
		and 
		&nbsp;<strong>
			following
		</strong>&nbsp;
		the Cut or Pasted changes. The result is much like doing a shuffle mode edit in ProTools. Ripple Cut and Paste are advanced features used to remove erroneous changes from incorrect lists, combine two or more changenotes into one, incorporate rebalances into a single note (see 
            &nbsp;<a href="#rebals">
			Rebalances
		</a>&nbsp;
		under 
		&nbsp;<a href="#xml_tips">
			Tips&#8230;
		</a>&nbsp;
		below), or reorganize the order of changes within a list.
	</p>
	<p>
		<strong>
			Ripple Insert Over
		</strong>&nbsp;
		performs a &nbsp;
		<strong>
			Ripple Insert
		</strong>&nbsp;
		of the clipped changes, but instead of inserting them between existing changes, it replaces the selected row or rows with the pasted ones and changes the &#8220;from&#8221; footages of the pasted changes to begin at the same place as the replaced row. This has the effect of rippling the pasted changes by an arbitrary amount. This admittedly confusing feature can be useful when the order of changes needs to be re-arranged.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="protools">
		Changenotes and ProTools
	</h3>
    <hr class="soften"/>
	<p>
		Let it be known that 
		&nbsp;<strong>
			mfChangeNote
		</strong>&nbsp;
		has no affiliation with Avid or the ProTools Audio Workstation. Also, and unfortunately, 
        ProTools lacks true inter-application communication with humble third-party apps like 
		&nbsp;<strong>
			mfChangeNote</strong>. That said, 
            &nbsp;<strong>
			mfChangeNote
		</strong>&nbsp;
		can automatically run certain ProTools functions through your Mac&#8217;s operating system using 
        features designed for accessibility, essentially typing into it just like you would do with your 
        own keyboard. 
		<p>To make this work, you will need to whitelist&nbsp;<strong>mfChangeNote</strong>&nbsp;in the "Security & Privacy"
		section of your Mac's "System Preferences" pane, under "Accessibility".</p>
	</p>
	<p>
		<strong>
			Workstation Send
		</strong>&nbsp;
		is the simplest and most useful feature. Like other Send menu features, the &nbsp;
		<strong>
			Workstation Send
		</strong>&nbsp;
		button will become active when &nbsp;
		<strong>
			mfChangeNote
		</strong>&nbsp;
		detects the availability of ProTools. Push the button and &nbsp;
		<strong>
			mfChangeNote
		</strong>&nbsp;
		will locate ProTools to the position and duration of the selected change.
	</p>
	<p>
		The Send menu has some other items that will locate ProTools to values such as the 
		UserFootage field and the SubTotal duration. With the Send menu you may also send 
		strings to TextEdit or your computer&#8217;s shared pasteboard.
	</p>
	<img src={send_menu_onPT} alt="workstation send button" />
	<p>
		The Send menu&#8217;s &nbsp;
		<strong>
			Perform Delete
		</strong>&nbsp;
		and &nbsp;
		<strong>
			Perform Insert Silence
		</strong>&nbsp;
		commands will locate ProTools to the selected row&#8217;s range, just like &nbsp;
		<strong>
			Workstation Send</strong>, and then will subsequently perform ⌘-B (Clear) or Shift-⌘-E (Insert). By keeping your session in Shuffle Mode, these commands can speed the manual performance of common conforming steps. For a real time-saver however, you should consider using 
		<strong>&nbsp;
			mfChangeNote</strong>&#8217;s &nbsp;
		<a href="#automation">
			automated conforming&nbsp;
		</a>
		tool.
	</p>
	<h4 id="gotoexpectedtailpop">
		Go To Expected Tail Pop
	</h4>
	<p>
		If, in the course of a difficult conform, you feel you may have erred, or lost your
		place in the changenote (perhaps you got hit with a blitz of urgent messages on Slack),
		you might need a way to check the accuracy of your work-in-progress conforming job.
	</p>
	<p>
		This trick requires you to have the "OldLFOA" entered in the proper field. Double-click the checkbox up to and including the last change you think you performed. 
		Then right-click on the "New LFOA" field in the upper right of the window.
	</p>
	<p>
		Select "Go To Expected Tail Pop" from the pop-up context menu and <strong>mfChangeNote</strong>
		&nbsp;will send ProTools to the location where the Tail Pop should be now, had you done your work correctly.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="conforming">
		Things to do before conforming
	</h3>
    <hr class="soften"/>
	<p>
		Before you conform your ProTools session, you must, in ProTools&#8230;
	</p>
	<ul>
		<li>
			Open a session, duh.
		</li>
		<li>
			Match the Main time display to the same Timecode or Feet+Frames format as &nbsp;
			<strong>
				mfChangeNote
			</strong>.&nbsp;
		</li>
		<li>
			Select the tracks you want to conform, and deselect the tracks you don&#8217;t want to conform.
		</li>
		<li>
			Get rid of any locked regions in the tracks you are going to conform.
		</li>
		<li>
			Put ProTools into "Shuffle" mode.
		</li>
	</ul>
	<p>
		Also, you might want to&#8230;
	</p>
	<ul>
		<li>
			Have a tail pop on all the tracks you are conforming.
		</li>
		<li>
			Enter the LFOA of the unconformed version into "Old LFOA" in your changenote.
		</li>
		<li>
			Have old and new guide tracks visible and near each other for comparison.
		</li>
		<li>
			Create a monster region group of everything in the tracks of interest.
		</li>
	</ul>

	<hr class="soften"/>
	<h3 class="lead" id="automation">
		Automated Conforming
	</h3>
    <hr class="soften"/>
	<p>
		<strong>
			mfChangeNote
		</strong>&nbsp;
		has the power to run some or all of the changes in your changenote automatically in a sequence over your ProTools session. With this awesome power comes the ability to run roughshod over all your hard work, maxxing out the undo cue and making Swiss Cheese out of your fine, aged Wisconsin Cheddar. Don&#8217;t hand this command over to an intern on their first day. Don&#8217;t run this command while drinking Robitussin.
	</p>
	<p>
		You&#8217;ve been warned; Now, here&#8217;s your magic wand, Harry. 
	</p>
	<p>
		If ProTools is open, &nbsp;
		<strong>
			and
		</strong>&nbsp;
		you &nbsp;
		<a href="#multiselect">
			select a range of multiple changes</a>, you may run all the selected changes automatically in one fell swoop by typing ⌘-R (Run Sequence&#8230;) and following the instructions in the Run Sequence dialogue box.
	</p>
	<figure>
		<img src={send_menu_run} alt="run dialogue" />
		<figcaption><small>
			ProTools must be open AND multiple changes must be selected
		</small></figcaption>
	</figure>
	<p></p>
	<p>
		The changes will be performed exactly as they are shown in the changenote, 
		so expanded groups will happen individually, one row at a time, 
		while collapsed groups will happen in one big chop.
	</p>
	<p>
		The magic of simulating keystrokes across applications has some limitations. 
		For one thing, it can&#8217;t happen as fast as you would like. Each change 
		takes about 3 seconds to complete. Also, you can&#8217;t be holding down any 
		keys when the sequence begins, you can&#8217;t switch away from ProTools at all, 
		and frankly, just keep your mitts off the keyboard and mouse because just &nbsp;
		<strong>thinking</strong> &nbsp;about touching them is likely to screw it all up.
	</p>
	<img src={run_sheet_during_5} alt="run dialogue" />
	<p>
		<strong>mfChangeNote</strong> will try to detect when ProTools has lost 
		the computer&#8217;s focus during a run and it will pause the sequence 
		until ProTools regains active status. Sadly for you, the pause in the sequence 
		almost always causes lost keystrokes and erratic behavior when you resume, 
		so you are better off cancelling, figuring out where you left off, and beginning 
		again. That&#8217;s what the tail pops are for.
	</p>
	<p>
	    Large sessions with lots of grouped regions can lag ProTools, which
		may then drop vital keystrokes. Likewise, running a conforming sequence while 
		the Task Manager is performing background tasks can also cause unreliable behavior.
		Watch the sequence as it runs and stop it if you see this happening.
	</p>
	<h4 id="shotmoveswhilerunningasequence">
		Shot moves while Running a Sequence
	</h4>
	<p>
		If your changenote was created by comparing two Adobe Premiere Pro xml sequences, 
        then <strong>mfChangeNote</strong> can perform a pair of Cut and Paste operations over certain groups of 
        changes that it recognizes as a &#8220;move&#8221; (see <a href="#create_shotmoves">Shot Moves</a> under 
		&nbsp;<a href="#create">Creating a Changenote from Adobe Premiere Pro</a>
		). You must Expand the groups for both the Cut and Paste changes that you wish to 
		automatically move (they will say &#8220;take out&#8221; and &#8220;put back in&#8221; 
		in their comment fields).
	</p>
	<p>
		If your changenote was created by importing an Avid change list text file, or if you 
		made it yourself, the automated sequence will perform all changes as either 
		&#8220;Clear&#8221; commands (⌘-B) or &#8220;Insert Silence&#8221; commands 
		(Shift-⌘-E). Therefore, moved shots will not get special treatment.
	</p>
	<h4 id="recommendedmoveworkaround">
		Recommended &#8220;Move&#8221; Workaround
	</h4>
	<p>
		If your changenote cannot automate a move as described above, here is my advice: 
	</p>
	<p>
		Select and automate all changes up to the first step in the move (avid-sourced notes will say things like &#8220;Move Shot to #9&#8221; or &#8220;Insert from #2&#8221;). Select the next change and push &#8220;Workstation Send&#8221;. If the move is a forward one (towards the head), then you will insert silence here, and paste when the time comes later. If the move is a backward one (towards the tail), then you should make a region group of the selection, move it somewhere safely downstream, and wait for the later change to re-insert it. You are safe to automate again from there until another move-related change comes up.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="tabtext">
		Exporting Tab-delimited Text
	</h3>
	<hr class="soften"/>

	<p>
		For interchange with other applications that may need change notes, but
		which do not support the &nbsp;<strong>mfChangeNote</strong>&nbsp; .mfC 
		file format, you may export a changenote as a tab-delimited text file.
	</p>
	<p>
		This feature was added in version 4.4.4, and is considered experimental. 
		The data format may be subject to change in future versions, so keep an 
		eye on this space if you intend to rely on this.
	</p>
	<p>
		An example of the output format looks like this:
		<pre>{`
   EVENT	START	ACTION	LENGTH	TYPE	COMMENT
   1    	10+00	-     	123+03	    	comment 1
   2    	20+00	+     	223+04	    	comment 2
   3    	30+00	-     	401+13	    	comment 3
		`}
		</pre>
		A complete proposal for a file specification can be found <a href="http://turnoverpost.com/change_note_text_spec">here</a>.
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="producing_text">
		Producing Acceptable Text Files
	</h3>
    <hr class="soften"/>
	<p>
		There are some programs that are capable of producing compatible input for &nbsp;
		<strong>mfChangeNote</strong> to import. When possible, it is usually better to let <strong>mfChangeNote</strong> do
		the heavy lifting with xml sequences.
	</p>
	<h4 id="avidmediacomposer">
		Avid Media Composer
	</h4>
	<p>
		The Avid Media Composer has a way to make change lists that can be imported 
        by <strong>mfChangeNote</strong>. You must use the FilmScribe utility Change List Tool. 
        Check only the &#8220;Show Only Changes&#8221; option. You may also use 
        the &#8220;Combine Deletions&#8221; option, but you don&#8217;t need to and it will 
        make your <strong>mfChangeNote</strong> less helpful.
	</p>
	<p>
		It is best to include only the necessary fields as already described in <a href="#open_avid">Importing a ChangeNote...&nbsp;</a> 
		In most cases you should only enable the &#8220;Clip Name&#8221; option.&nbsp; 
		<strong>mfChangeNote</strong> does not import scene/take or comment fields from avid-generated change lists.
		The "At This Footage" and "For This Length" fields must be formatted as Feet and Frames: i.e. <strong>"100+00"</strong>
	</p>

	<hr class="soften"/>
	<h3 class="lead" id="xml_tips">
        Tips for best results from <strong>mfChangeNote</strong> xml comparisons
	</h3>
    <hr class="soften"/>
	<p>
		In order for <strong>mfChangeNote</strong> to give you the most useable and accurate output 
        when comparing two versions, it may be necessary to prepare your sequences. 
        The goal is to make the &#8220;after&#8221; list a direct descendent of the &#8220;before&#8221; list, 
        as much as possible, and to avoid gaps between shots.
	</p>
	<p>
		Because consistency between reels is important in making a changenote, you may need to 
        exercise discipline when cutting, or do a little work on a copy of both sequences before 
        you run the &#8220;create&#8221; operation.
	</p>
	<p>
		<strong>mfChangeNote</strong> matches clip names on a single track.
	</p>
	<p>
		These things affect the accuracy of the result: 
	</p>
	<ul>
		<li>
			renaming clips
		</li>
		<li>
			moving clips from track to track
		</li>
		<li>
			flattening sequences and multiclips.
		</li>
		<li>
			changing leaders
		</li>
		<li>
			extra material after the LFOA
		</li>
	</ul>
	<h4 id="turnoverpreparation">
		Turnover preparation
	</h4>
	<p>
		Sometimes these operations must be done in the normal course of editing. For consistency, I recommend that you 
		&nbsp;<strong>
			make a copy of your sequence for each turnover, and
		</strong>&nbsp;
		that you 
		&nbsp;<strong>
			consistently apply the following corrections every time.</strong>&nbsp;
	</p>
	<ol>
		<li>
			<strong>
				Flattening multiclips is recommended.
			</strong>&nbsp;
			However, this changes the clipname, which is the way <strong>mfChangeNote</strong> identifies 
            new clips. If you choose to flatten your multiclips, do the same thing in 
            your &#8220;before&#8221; sequence.
		</li>
		<li>
			<strong>
				Flatten nested sequences,
			</strong> but you only need to do this  <strong>
				if changes were made inside the sequences.</strong> When in doubt, flatten them out. 
                This means replacing those nested sequences with the actual edited clips they represent.
		</li>
		<li>
			<strong>Consolidate</strong>, or manually flatten, 
            only the <strong>significant shots</strong> to a single video track, typically V1. The 
			<em>
            &nbsp;&#8220;main&#8221;&nbsp;
			</em>
			clip at any moment in the timeline should be carefully dragged down to V1 so that all gaps are filled. Overlays, split screens, titles, processing layers and hidden or non-opaque clips should not go to V1.
		</li>
		<li>
			<strong>
				Check for clipname changes</strong>. Match any differences by fixing the &#8220;before&#8221; list.
		</li>
		<li>
			<strong>
				Use a consistent head leader</strong>. The sequence should always start at frame 0. In conventional feature film practices, the first 192 frames will be a head leader. Use the same leader clip always, or you may put nothing at all in the first 192 frames.
		</li>
		<li>
			<strong>
				Clear the tail</strong>. Always use the same clip for a tail leader, but it is usually best just to remove all material after the LFOA.
		</li>
		<li>
			<strong>
				Export the xml for each prepared sequence as an individual file</strong>. &nbsp;
			<strong>
				mfChangeNote
			</strong> expects a single sequence in the xml file, but it will try to use the first sequence it finds, if more than one sequence is present.
		</li>
	</ol>
	<h4 id="generaleditroomprinciples">
		General Edit Room Principles
	</h4>
	<p>
		Above all, and not just for the sake of your turnovers, you should have a consistent and meaningful convention for clip naming and follow it rigorously. In features, a good way is to name dailies with scene, take, and camera designation. A dis-ambiguating tag, like a serial number, can help prevent redundant names when naming conventions are not enough. Rendered clips, placeholder clips, storyboards, and VFX versions should also have clip naming rules that reflect the need for unique identification (or not, as the case may be).
	</p>
	<h4 id="rebals">
		Rebalances
	</h4>
	<p>
		As you know, feature films are commonly broken down into &#8220;reels&#8221;, units of approximately 20 minutes each. The reason for this is not purely historical, of course, it is a practical necessity for the division of labor needed in the professional business of post-production. Most edit rooms have their own policies for when and how to compose these reels, but at some point in nearly every feature project&#8217;s lifetime, a &#8220;rebalance&#8221; is necessary between turnovers, moving scenes or shots from one reel into another.
	</p>
	<p>
		Best practice for rebalances is to completely separate the changes from the moves. 
        The way to do this is create a turnover <strong>before</strong> moving anything from one 
        reel to another, then another <strong>after</strong> the move has been made. 
        By isolating the version in the middle, the &#8220;rebal&#8221; version, 
        you will not lose track of the shots when they pass between reels. Without this precaution, 
        shots taken out of the old version of one reel will be treated as deletions, and then they 
        will re-appear as brand new scenes in the next version of a different reel. Since changes may 
        have happened within these scenes along the way, recovering sync on the moved stuff is going to 
        be haphazard and time-consuming. It is typically not necessary to render new video for 
        the &#8220;rebal&#8221; version, only the edit lists.
	</p>
	<hr class="soften"/>
    <hr class="soften"/>
	<br/><br/><br/><br/>
</div>