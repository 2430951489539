import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import MainBody    from './components/MainBody'
import MfcCarousel from './components/MfcCarousel'
import MfcManual   from './components/MfcManual'
import MfcBuy      from './components/MfcBuy'

import './App.css';
import mfClogoicon from './img/brand/mf_change_logo_flat_rounded_corners.png'

class MFCNav extends React.Component {
  render() {
    return (
      <div>
        <Navbar role="navigation" color="dark" dark expand="md" fixed="top">
          <NavbarBrand href="/">
            <img src={mfClogoicon} alt='' style={{width:'30px',height:'30px'}}/>
            &nbsp;mfChangeNote
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/#">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#about">About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#contact">Contact</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/buy">Buy</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/manual">Using mfChangeNote</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    );
  }
}
const MainPage = () => 
<div style={{ paddingTop: '40px', marginBottom:'-16px' }}>
  <MfcCarousel />
  <MainBody />
</div>;
const BuyPage = () => 
<div style={{ paddingTop: '20px', marginBottom:'-16px' }}>
  <MfcBuy/>
</div>;
const ManualPage = () => 
<div style={{ paddingTop: '60px' }}>
  <MfcManual/>
</div>;

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <MFCNav />
          </header>
          <main>
            <Switch>
              <Route path="/" exact component={MainPage} />
              <Route path="/buy" exact component={BuyPage} />
              <Route path="/manual" exact component={ManualPage} />
              <Route component={MainPage} />
            </Switch>
          </main>
          <footer className="App-footer">
            <p><small>&copy;the website and the program, by me 2022</small></p>
          </footer>
        </div>
      </Router>
    );
  }
}
// function NotFound({ location }) {
//   return (
//     <div>
//       <h3>
//         <code>{location.pathname} Not Found</code>
//       </h3>
//     </div>
//   );
// }
export default App;
