import React, { Component } from 'react';
import { Container,Row,Col } from 'reactstrap';
import sketch01 from "../img/sketch/import_text_file_sketch.png"
import sketch02 from "../img/sketch/calculator_sketch.png"
import sketch03 from "../img/sketch/plain_scissor_sketch.png"
import sketch04 from "../img/sketch/generator_factory_sketch01.png"
import sketch05 from "../img/sketch/robot_cutter_sketch01.png"

class MainBody extends Component {
  render() {
    return <div className='App-content' >
    <Container>
            <Row>
                <Col md="4">
                    <img src={sketch01} alt="" className="minicartoon"/>
                    <h2 className='skinnyslogan'>Import</h2>
                    <p className="lead">Easy Data Entry</p>
                    <p>mfChangeNote supports common change lists generated by Avid Media Composer and others. But in a pinch, you can make, share, and print your own change notes from scratch.</p>
                    <p><a className="btn" href="/manual#open_avid">View details &raquo;</a></p>
                </Col>
                <Col md="4">
                    <img src={sketch02} alt="" className="minicartoon"/>
                    <h2 className='skinnyslogan'>Calculate</h2>
                    <p className="lead">Error-free Conforming</p>
                    <p>Built-in tricks for handling timecode and feet-frames math mean you never have to keep track of footages, or do math in your head.</p>
                    <p><a className="btn" href="/manual#checkingtheboxes">View details &raquo;</a></p>
               </Col>
                <Col md="4">
                    <img src={sketch03} alt="" className="minicartoon"/>
                    <h2 className='skinnyslogan'>Modify</h2>
                    <p className="lead">Make it Right</p>
                    <p>Like a word processor or spreadsheet, you have complete freedom to fix, annotate, format, or simplify your change note whenever you want.</p>
                    <p><a className="btn" href="/manual#editing">View details &raquo;</a></p>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <img src={sketch04} alt="" className="minicartoon"/>
                    <h2 className='skinnyslogan'>Generate</h2>
                    <p className="lead">Perfect changes from Premiere Pro</p>
                    <p>Take two sequences and compare them. mfChangeNote will give you an accurate change note that even simplifies shot moves in the most efficient way.</p>
                    <p><a className="btn" href="/manual#create">View details &raquo;</a></p>
                </Col>
                <Col md="6">
                    <img src={sketch05} alt="" className="minicartoon"/>
                    <h2 className='skinnyslogan'>Automate</h2>
                    <p className="lead">Hands off the wheel, eyes on the road</p>
                    <p>Free your brain. Push a button to automatically sequence any or all of your changes in ProTools. And you never have to give up control over the process. mfChangeNote won't slash and burn through your session, unless you want it to.</p>
                    <p><a className="btn" href="/manual#automation">View details &raquo;</a></p>
                </Col>
            </Row>
            
            <p id="about"></p>
            <hr className="soften"/>
            <Container id="about">
                <h2>About mfChangeNote<small>...and why you might want it.</small></h2>
                <p>Television and Film Post-production schedules continue to overlap more and run shorter, putting ever-growing demands on the people who are preparing and finishing the sound track. And yet, as modern editing systems offer greater complexity and efficiency, the handling of versioning and turnovers remains in the stone age.</p>
                <p>mfChangeNote is a Mac program (OS X 10.8+, 64-bit Intel) designed to help all the editors and assistants who must manually conform audio tracks, guide tracks, and even their notes or metadata whenever picture changes make versions obsolete.</p>
                <p>mfChangeNote makes change notes, prints them, and helps you use them to conform (aka 're-conform') sound tracks.</p>
            </Container>
            
            <hr className="soften"/>
            <Container id="contact">
                <h2>Contact me</h2>
                <p>I'm just another humble sound editor, probably much like yourself. Over the last few decades, my passion for film-making, sound, and computer science have led me to every corner of the post-production universe. Shoot me off a note with your questions and suggestions. I'd love to hear them.</p>
                <a href="mailto:admin@mfchangenote.com?Subject=mfChangeNote%20Website%20Contact"><p>admin@mfchangenote.com</p></a>
            </Container> 
            <hr className="soften"/>   
            <hr className="soften"/>  
            <p><br/></p><p><br/></p><p><br/></p>    
    </Container>
    </div>
  }
}

export default MainBody