import React, { Component } from 'react';
import { Container, Row, Col,Nav } from 'reactstrap';
import { manual_contents, manual_body } from './ManualElements'

class MfcManual extends Component {
    render() {
        return <div className='App-content'>
            <Container>
                <Row>
                    <div id="doc_top" class="mfc-page-top-spacer"></div>
                    <Col md='9'>
                        {manual_body()}
                    </Col>
                    <Col md='3'>
                        <Nav stacked className="mfc-docs-sidebar" role="complementary">
                            {manual_contents()}
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}
export default MfcManual